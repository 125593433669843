import React, { useState } from 'react';
import axios from 'axios';
import ExampleComponent from './components/ExampleComponent';

function App() {
  const [userInfo, setUserInfo] = useState('');
  const [examples, setExamples] = useState([]);

  const handleChange = (e) => {
    setUserInfo(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/generate', { userInfo });
      setExamples(response.data.examples);
    } catch (error) {
      console.error('Error generating examples:', error);
    }
  };

  return (
    <div className="App">
      <h1>Webseite Generator</h1>
      <form onSubmit={handleSubmit}>
        <label>
          User Information:
          <input type="text" value={userInfo} onChange={handleChange} />
        </label>
        <button type="submit">Generate Examples</button>
      </form>
      <div>
        {examples.map((example, index) => (
          <ExampleComponent key={index} example={example} />
        ))}
      </div>
    </div>
  );
}

export default App;
